import React, { useEffect, useState } from "react";
import Style from "./calculator/Style.module.css";
import PrimaryLink from "./PrimaryLink";

const Calculator = () => {
  const min = 1000;
  const step = 500;
  const max = 20000;
  const percent = 2.2;

  const [value, setValue] = useState(2000);
  const [progress, setProgress] = useState(0);
  const [percentTotal, setPercentTotal] = useState(0);
  const [amount, setAmount] = useState();

  useEffect(() => {
    const result = ((value - min) / (max - min)) * 100;
    setProgress(result);
  }, [value]);

  useEffect(() => {
    let percentTotal = (value / 100) * percent * 14;
    percentTotal = Math.round(percentTotal * 1000) / 1000;
    setPercentTotal(percentTotal);

    let amount = percentTotal + parseInt(value);
    setAmount(amount);
  }, [value]);

  return (
    <div className="relative max-w-3xl">
      <div className={Style.transformed}></div>
      <div className="flex flex-col gap-5 rounded-3xl p-8 relative">
        <div className="flex flex-col gap-3">
          <div className="text-base">Сума та пільговий термін кредиту</div>
          <div className="text-2xl">{value} грн</div>

          <div className="relative">
            <input
              type="range"
              className={`w-full ${Style.range}`}
              min={min}
              max={max}
              step={step}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <div className="h-1 w-full bg-gray absolute left-0 top-5"></div>
            <div
              className="h-1 bg-emerald-500 absolute left-0 top-5"
              style={{ width: progress + "%" }}
            ></div>
          </div>
        </div>

        <div className="flex justify-between items-center gap-3">
          <Item title={"Ви берете:"} text={` ${value} грн`} />
          <Item title={"Відсоток:"} text={`${percentTotal} грн`} />
          <Item title={"До сплати:"} text={`${amount} грн`} />
        </div>

        <div className="flex justify-center">
          <PrimaryLink href={`https://app.avocredit.com.ua/${window.location.search}`}>
            Оформити
          </PrimaryLink>
        </div>

        <div>
          <p className="text-xs text-slate-500">
            *Ця інформація не є кредитним калькулятором у розумінні НПА НБУ.
            Детальні розрахунки витрат на кредит з урахуванням вибраних умов
            (калькулятори по кожному кредитному продукту) залежить від
            кредитодавця
          </p>
        </div>
      </div>
    </div>
  );
};

const Item = ({ title, text }) => {
  return (
    <div className="flex flex-col">
      <div className="text-sm">{title}</div>
      <div className="lg:text-lg text-base">{text}</div>
    </div>
  );
};

export default Calculator;
