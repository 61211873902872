import pattern from "./img/pattern.png";
import cheerful_girl from "./img/cheerful_girl.png";
import Calculator from "./components/Calculator";
import GetLoan from "./components/GetLoan";
import Howgetloan from "./components/Howgetloan";
import Certificate from "./components/Certificate";
import logo from "./img/logo_mini.png";

function App() {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center p-3 gap-3">
        <div className="text-3xl">
          <img src={logo} alt="" className="h-7 lg:h-14" />
        </div>

        <div>
          <ul className="flex gap-2 items-center">
            <li className="text-sm lg:text-base">
              <a href="#getloan">Вимоги</a>
            </li>
            <li className="text-sm lg:text-base">
              <a href="#howgetloan">Як отримати</a>
            </li>
            <li className="text-sm lg:text-base">
              <a href="#certificate">Довідка</a>
            </li>
          </ul>
        </div>
      </div>

      <div
        style={{ backgroundImage: `url("${pattern}")` }}
        className="w-full rounded-3xl bg-yellow p-5 lg:p-0"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
          <div className="flex relative lg:order-last lg:h-full lg:pr-20">
            <div className="absolute top-0 right-6 lg:static">
              <img
                src={cheerful_girl}
                alt="cheerful_girl"
                className="h-32 lg:h-full"
              />
            </div>
          </div>

          <div className="flex flex-col gap-8 lg:pl-16">
            <h1 className="lg:text-5xl text-3xl font-medium">
              Гроші в кредит <br />
              до зарплати
            </h1>
            <div className="pb-5">
              <Calculator />
            </div>
          </div>
        </div>
      </div>

      <GetLoan />
      <Howgetloan />
      <Certificate />

      <div className="text-sm text-center px-2 lg:px-0 lg:py-16 py-8">
        * "SKYCREDIT" це зручний сервіс для підбору найкращіх пропозиціх від МФО
        України. В залежності від запропонованної компанії інформація що до умов
        кредиту може <br /> відрізнятися. Вище вказана інформація є примірную та
        не является офертою. Повний перелік кредитодавців які беруть участь у
        підборі знаходится за{" "}
        <a href="https://app.avocredit.com.ua/">посиланням</a>
      </div>

      <div className="border border-gray"></div>

      <div className="grid lg:grid-cols-12 gap-3 px-2 lg:px-0 lg:pt-16 pt-8">
        <div className="col-span-6">
          <img src={logo} alt="" className="h-7 lg:h-14" />
        </div>
        <div className="text-xs col-span-6">
          Проект здійснює підбір фінансових продуктів для клієнтів, будучи
          посередником між клієнтом, який бажає отримати фінансування, та
          кредитною установою, яка займається фінансуванням. SkyCredit не є
          фінансовою компанією та здійснює свою діяльність у рамках
          соціально-правового законодавства України. Робота цього сервісу
          пов'язана із здійсненням інвестиційної діяльності. Усі матеріали цього
          сайту охороняються законодавством про авторське право. Остаточні умови
          уточнюйте на веб-сайті кредитної організації. Сайт використовує cookie
          для надання послуг. Умови зберігання або доступу до cookie Ви можете
          настроїти у своєму браузері. Сервіс призначений для осіб віком від 18
          років.
        </div>
      </div>
      <div className="text-center text-xs text-gray100 py-5 lg:py-8">
        © 2023
      </div>
    </div>
  );
}

export default App;
