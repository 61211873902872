import React from "react";
import Style from "./howgetloan/Style.module.css";

const Howgetloan = () => {
  return (
    <div className="lg:pt-16 pt-8">
      <div className="grid grid-cols-1 gap-5 lg:gap-10">
        <h2
          className="lg:text-6xl text-3xl font-medium text-center"
          id="howgetloan"
        >
          Як отримати кредит
        </h2>
        <div className="grid lg:grid-cols-3 gap-5">
          <div className="relative col-span-1 bg-yellow lg:bg-inherit">
            <div className={Style.transformed_yellow}></div>
            <InfoBlok
              title={"1. Вкажіть суму і срок"}
              text={
                "На головній сторінці сайту введіть суму і строк вашого кредиту."
              }
            />
          </div>

          <div className="relative col-span-1 bg-olive lg:bg-inherit">
            <div className={Style.transformed_olive}></div>
            <InfoBlok
              title={"2. Заповніть заявку"}
              text={
                "Процес триває 8 хвилин за першого звернення і 2 хвилини — за повторних."
              }
            />
          </div>

          <div className="relative col-span-1 bg-azure lg:bg-inherit">
            <div className={Style.transformed_azure}></div>
            <InfoBlok
              title={"3. Отримайте гроші"}
              text={
                "Після всіх процедур гроші будуть зараховані на вашу картку миттєво."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoBlok = ({ title, text, className }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-3 p-8 relative z-10">
      <div className="lg:text-xl text-lg font-medium">{title}</div>
      <div className="text-md text-justify"> {text}</div>
    </div>
  );
};

export default Howgetloan;
