import React, {useState} from "react";
import Spin from "./Spin";

const PrimaryLink = ({ href, children, ...props }) => {
  const onClickFunc = (e,href) => {
    e.preventDefault();
    window.gtag('event', 'purchase', {});
    setProcessing(true);
    setTimeout(() => {
        window.location.href = href
    }, 1000);
  };

  const [processing, setProcessing] = useState(false);

  return (
    <button
      className="px-12 py-3 bg-emerald-500 text-center rounded-3xl text-white hover:bg-emerald-600 flex gap-3 items-center disabled:bg-slate-400"
      onClick={e=>onClickFunc(e,href)}
      disabled={processing}
      {...props}
    >
      {processing && <Spin />}
      <span>
        {children}
      </span>
    </button>
  );
};

export default PrimaryLink;
