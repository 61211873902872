import React from "react";
import CreditCard from "./icons/CreditCard";
import Passport from "./icons/Passport";
import Phone from "./icons/Phone";
import Exclamation from "./icons/Exclamation";

const GetLoan = () => {
  return (
    <div className="lg:pt-16 pt-8">
      <div className="grid grid-cols-1 gap-5 lg:gap-10">
        <div
          className="lg:text-6xl text-3xl font-medium text-center"
          id="getloan"
        >
          Що потрібно для <br />
          отримання кредиту
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5">
          <Item
            icon={<Exclamation className="h-12 lg:h-20" />}
            title="Вік: від 18 років"
          />
          <Item
            icon={<CreditCard className="h-12 lg:h-24" />}
            title="Банківська карта"
          />
          <Item
            icon={<Passport className="h-12 lg:h-24" />}
            title="Паспорт та ІПН"
          />
          <Item
            icon={<Phone className="h-12 lg:h-20" />}
            title="Мобільний телефон"
          />
        </div>
      </div>
    </div>
  );
};

const Item = ({ icon, title }) => {
  return (
    <div className="flex flex-col h-24 lg:h-40 gap-2">
      <div className="flex justify-center items-center grow">{icon}</div>
      <div className="text-center text-lg lg:text-xl">{title}</div>
    </div>
  );
};

export default GetLoan;
